.day_use_activity_details_section_wrapper {
    background: var(--ion-color-secondary);
    width: 100%;
    left: 0;
    right: 0;
    margin: 20px auto 20px auto;
    display: flex;
    height: auto;
}

.day_use_activity_details_title {
    text-align: left;
    height: 27px;
    max-height: 30px;
    color: var(--ion-color-primary);
    width: 100%;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font: normal normal bold 24px/27px Arial;
}

.day_use_activity_details_price {
    width: 100%;
    text-align: left;
    height: 22px;
    white-space: nowrap;
    overflow: hidden;
    color: var(--ion-color-primary);
    text-overflow: ellipsis;
    font: normal normal 600 16px/22px Arial;
}


.day_use_activity_details_description {
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 62px;
    font: normal normal normal 16px/20px Arial;
    color: var(--ion-color-primary);
}

.day_use_activity_details_read_more {
    text-align: left;
    cursor: pointer;
    text-decoration: underline;
    padding: 10px 0;
    font: normal normal normal 16px/22px Arial;
    color: var(--ion-color-primary);
    max-width: 115px;
}

.day_use_activity_details_avail_button_wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
}

.fade {
    opacity: 0.4;
    pointer-events: none;
}

.icon {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}