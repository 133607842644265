.InformationalBox {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--ion-color-primary);
    border: 1px solid transparent;
    
}
.InformationalBox__content {
    font-size: 12px;
    color: var(--ion-color-secondary);
    padding: .5rem;
}