.RegisteredCard {
  height: 80vh;
  max-height: 700px;
  background-color: white;
  z-index: 1000;
  text-align: center;
  padding-bottom: 10px;
  overflow: auto;
  color: var(--ion-color-primary);
}
@media screen and (min-height: 228px) and (max-height: 750px) {
  .RegisteredCard {
    overflow-y: scroll;
  }
}
