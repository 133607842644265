.policies {
    padding-left: 10px;
    padding-bottom: 10px;
    width: 100%;
}
.policies > div {
    width: 90%;
}
.policies * {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.policies *::after {
    display: none !important;
}