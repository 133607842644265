.LogoAndTitle__imageWrapper {
  position: relative;
  width: 100%;
  height: 55px;
  background-color: var(--ion-color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
}
.LogoAndTitle__cancel {
  position: absolute;
  right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
