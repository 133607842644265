/* Removing thumbnail box shadow */
.bl-rm-box-shadow-btn>ion-button::part(native) {
  box-shadow: none;
}

/* Adding transperent background to ion item */
.bl-transperent_box::part(native) {
  background: rgba(255, 0, 0, 0) !important;
}

.key-board-offset-reset {
  --keyboard-offset: 0 !important;
}

/* --------------------------- */
/* Button global styles */
ion-button {
  text-transform: none;
  font-size: 18px;
}

.bl-btn-no-padding>ion-button::part(native) {
  padding: 0;
}

@media screen and (min-width: 340px) and (max-width: 400px) {
  .bl-button-half-padding::part(native) {
    padding-left: 8px;
    padding-right: 8px;
  }
}

/* --------------------------- */

/* --------------------------- */
ion-label {
  font-weight: bold;
}

/* --------------------------- */

/* --------------------------- */
/* Remove click animation */
ion-select {
  width: 100%;
  --ripple-color: transparent;
  --background-hover: transparent;
}

/* --------------------------- */

/* --------------------------- */
/* Popup modal styling */
.bl-popup {
  --ion-background-color: var(--ion-color-light);
  background: rgba(0, 0, 0, 0.3);
}

.bl-popup::part(content) {
  --min-width: 250px;
  /* width: 90%; */
  --height: fit-content;
  --border-radius: 6px;
  padding: var(--bl-padding-xs);
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

@media screen and (min-width: 250px) and (max-width: 950px) {
  .bl-popup::part(content) {
    width: 80%;
    --max-width: 650px;
  }
}

/* --------------------------- */

/* --------------------------- */
ion-datetime-button::part(native) {
  background: white;
}

/* --------------------------- */

/* --------------------------- */
/* Remove item field hover effect */
ion-item {
  --background-hover: var(--background);
}

/* --------------------------- */

/* --------------------------- */
/* Info box background */
.info>ion-item::part(native) {
  background: --ion-color-informational;
}

/* --------------------------- */


/* --------------------------- */
/* Popup styles new */

.bl-popup-details {
  --ion-background-color: var(--ion-color-light);
}

.bl-popup-details::part(content) {
  --min-width: 250px;
  width: 95%;
  height: 87%;
  --height: fit-content;
  --border-radius: 6px;
  padding: var(--bl-padding-lg);
}

/* --------------------------- */

.bl-inline-modal {
  --ion-background-color: var(--ion-color-light);
  bottom: calc(58px + env(safe-area-inset-bottom));
}

.bl-inline-modal::part(content) {
  --min-width: 250px;
  width: 100%;
  --height: fit-content;
  --border-radius: 6px;
  padding: var(--bl-padding-lg);
  position: absolute;
  bottom: 0;
}

.bl-inline-modal-55percH::part(content) {
  height: 55%;
}

.bl-inline-modal-60percH::part(content) {
  height: 60%;
}

.bl-inline-modal-70percH::part(content) {
  height: 70%;
}

.bl-inline-modal-85percH::part(content) {
  height: 85%;
}

.bl-number-field::part(native) {
  background: rgb(242, 242, 244);
  --inner-padding-end: 0px;
}

.bl-number-field-inner-p::part(native) {
  --inner-padding-end: 0px;
}

/* ion-item::part(native)  {
  padding-left: 10px;
} */
/* --------------------------- */

ion-card {
  box-shadow: none;
}

.inner-item-no-padding::part(native) {
  --inner-padding-end: 0px;
}

/* ------------ Search bar --------------- */
.bl-search-icon {
  position: absolute;
  left: unset;
  right: 16px;
  fill: var(--ion-color-secondary);
  z-index: 2;
}

ion-searchbar {
  padding: 0 !important;
}

ion-searchbar>.searchbar-input-container ion-icon.searchbar-search-icon {
  display: none !important;
}

ion-searchbar>.searchbar-input-container>.searchbar-input {
  padding-left: 8px;
  height: 48px;
  --border-radius: 3px;
  box-shadow: none;
}

/* --------------------------- */

/* ------------ Sort by select desktop --------------- */
.bl-sort-by-select {
  background: var(--ion-color-white);
  color: #38414B;
  height: 56px;
  border: 2px solid var(--ion-color-secondary);
  border-radius: 5px;
  padding: 16px;
  font-size: 16px;
  text-align: start;
}

ion-popover::part(content) {
  min-width: 300px;
  right: 50px;
}

/* --------------------------- */

/* ------------ Sort by select mobile --------------- */
.bl-sort-by-select-options-mobile>.alert-wrapper {
  max-width: 350px;
  width: 100%;
}

.bl-sort-by-select-mobile::part(icon) {
  display: none;
}

.bl-sort-by-select-mobile::part(text) {
  color: var(--ion-color-secondary);
  height: 24px;
  font-size: 18px;
  text-align: end;
  font-weight: bold;
  max-width: 70px;
}

.bl-sort-by-select-mobile {
  width: 70px;
}

/* --------------------------- */