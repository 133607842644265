.container {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 32px;
}

.item {
  flex: 1;
  text-align: center;
}

.item:not(:last-child) {
  position: relative;
}

.item:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 35%;
  transform: translate(50%, -50%);
  width: 50%;
  height: 1px;
  background-image: linear-gradient(to right, transparent 50%, var(--ion-color-primary) 50%);
  background-size: 10px 1px;
}

@media screen and (min-width: 320px) and (max-width: 399px) {
  .item:not(:last-child)::after {
    top: 30%;
    transform: translate(90%, -50%);
    width: 35%;
  }
}

@media screen and (min-width: 400px) and (max-width: 600px) {
  .item:not(:last-child)::after {
    top: 30%;
    transform: translate(60%, -50%);
    width: 45%;
  }
}

@media screen and (min-width: 700px) and (min-width: 799px) {
  .item:not(:last-child)::after {
    transform: translate(40%, -50%);
    width: 55%;
  }
}

@media screen and (min-width: 800px) and (max-width: 900px) {
  .item:not(:last-child)::after {
    transform: translate(35%, -50%);
    width: 60%;
  }
}

@media screen and (min-width: 900px) {
  .item:not(:last-child)::after {
    transform: translate(25%, -50%);
    width: 65%;
  }
}

@media screen and (min-width: 1023px) {
  .item:not(:last-child)::after {
    transform: translate(50%, -50%);
    width: 50%;
  }
}
