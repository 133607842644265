
:root {
  -webkit-tap-highlight-color: transparent;
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.no-ripple {
  --ripple-color: transparent;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3 {
  font-weight: bold;
}

.title_font_family {
  font-family: "Georgia_normal";
}

.temp_circle_button {
  width: 35px;
  height: 35px;
  --border-radius: 50%;
}