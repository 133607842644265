.popUpWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.grayOutScreen {
  background-color: #ccc;
  opacity: 0.5;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}
.InfoBox,
.alertBox {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: white;
  position: fixed;
  z-index: 100;
  width: 600px;
  max-width: 600px;
  height: auto;
  min-height: 250px;
  border: 2px solid var(--ion-color-primary);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px;
}
.InfoBox p,
.alertBox p {
  text-align: center;
  padding-bottom: 1.5%;
}
.InfoBox > div {
  padding: 10px;
}
.alertBox > .alertBoxButtons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: space-around;
  justify-content: center;
  padding-bottom: 20px;
}
.InfoBox > div > * + *,
.alertBox > div > * + * {
  margin-left: 15px;
}
.infoDetailsBox {
  margin-bottom: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: var(--ion-color-light);
  position: fixed;
  z-index: 100;
  width: 80%;
  max-width: 80%;
  height: auto;
  min-height: 62%;
  border: 2px solid var(--ion-color-primary);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px;
}
.infoDetailsBox > .closeButtons {
  width: 3%;
  height: 5%;
  display: flex;
  flex-direction: row;
  justify-content: right;
  position: absolute;
  top: 30px;
  right: 30px;
}
@media screen and (min-width: 280px) and (max-width: 1324px) {
  .InfoBox,
  .alertBox {
    width: 95%;
    max-width: 600px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .InfoBox p,
  .alertBox p {
    font-size: 18px;
  }
  .InfoBox > * + *,
  .alertBox > * + * {
    margin-top: 25px;
  }
}
