.RegisterCardHeader {
  width: 90%;
  height: 50px;
  margin: 0 auto;
  display: flex;
  padding-left: 13px;
  padding-right: 13px;
  justify-content: space-between;
  align-items: center;
}
.RegisterCardHeader__exit {
    font-weight: bold;
    cursor: pointer;
    color: gray;
}