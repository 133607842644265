.day_use_locations_header_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
}

.day_use_locations_card_map {
    width: 100%;
    height: 110px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.day_use_locations_card_link_wrapper a {
    display: flex;
    padding: 15px 0;
    justify-content: space-between;
    align-items: center;
}

.day_use_locations_card_link {
    padding-left: 5px;
    text-decoration: underline;
}

.day_use_locations_link_map_wrapper {
    display: flex;
    align-items: center;
    width: 100%;
}