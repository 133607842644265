.day_use_basket_card_top_wrapper {
    background: var(--ion-color-secondary);;
    width: 100%;
    left: 0;
    right: 0;
    margin-bottom: 16px;
}

.day_use_basket_card_details {
    background-color: white;
    display: flex;
    height: 223px;
}

.day_use_basket_card_image {
    height: 100%;
    width: 40%;
}

.day_use_basket_card_summary {
    padding: 10px;
    width: 60%;
    justify-content: space-between;
    display: flex;
    background-color: var(--ion-color-secondary);
    color: var(--ion-color-primary);
}

.day_use_basket_card_title {
    font: normal normal bold 24px/27px Arial;
    color: var(--ion-color-primary);
}

.day_use_basket_card_sub_title {
    font: normal normal 300 16px/22px Arial;
    text-align: left;
    color: var(--ion-color-primary);
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}