.CardRegistration {
    width: 100%;
    height: 100%;
    padding-top: 15px;
    padding-bottom: 10px;
    position: relative;
}
.CardRegistration__pxp {
    position: relative;
    z-index: 1;
}
.CardRegistration__loaders {
    width: 100%;
    padding-left: 30px;
    padding-right: 20px;
    position: absolute;
    top: 110px;
}
.CardRegistration__step {
    padding-top: 25px;
}
.CardRegistration__loader_and_desc {
    display: flex;
    align-items: center;
    padding-top: 15px;
}
.CardRegistration__desc {
    margin-left: 20px;
    font-weight: bold;
}
.CardRegistration__success {
    width: 25px;
    height: 25px;
}