.bl_Paragraph_itinerary_text_ellipsis {
    display: block;
    width: 90px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.bl_Paragraph_itinerary_text_noconflict {
    display: block;
    width: 110px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.bl_Paragraph_itinerary_text_checkin {
    display: block;
}

.bl_Paragraph_itinerary_notbooked_text_ellipsis {
    display: block;
    width: 130px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

@media screen and (min-width: 350px) {
    .bl_Paragraph_itinerary_text_ellipsis {
      width: 120px;
    }
    .bl_Paragraph_itinerary_text_noconflict {
      width: 150px;
    }
    .bl_Paragraph_itinerary_notbooked_text_ellipsis {
      width: 160px;
    }
    .bl_Paragraph_itinerary_text_checkin {
      display: block;
    }
}

@media screen and (min-width: 400px) {
  .bl_Paragraph_itinerary_text_ellipsis {
    width: 170px;
  }
  .bl_Paragraph_itinerary_text_noconflict {
    width: 200px;
  }
  .bl_Paragraph_itinerary_notbooked_text_ellipsis {
    width: 210px;
  }
  .bl_Paragraph_itinerary_text_checkin {
    display: block;
  }
}

@media screen and (min-width: 440px) {
  .bl_Paragraph_itinerary_text_ellipsis, 
  .bl_Paragraph_itinerary_notbooked_text_ellipsis, 
  .bl_Paragraph_itinerary_text_noconflict {
    width: 100%;
  }
  .bl_Paragraph_itinerary_checkin {
    display: block;
  }
}