.ToolTip {
    position: relative;
    padding: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .ToolTip__icon {
    width: 25px;
    height: 25px;
  }
  .tooltipAction {
    position: absolute;
    /* left: -153px;
    top: 30px; */
    width: 300px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.42857143;
    text-align: left;
    text-align: start;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    font-size: 12px;
    z-index: 2;
    display: inline-block;
  }
  
  .tooltipAction.right {
    margin-left: 5px;
  }
  
  .tooltipAction.left {
    margin-left: -5px;
  }
  
  .tooltipAction.top {
    margin-top: -5px;
  }
  
  .tooltipAction.bottom {
    margin-top: 5px;
  }
  
  .tooltipAction .tooltipArrow {
    top: 50%;
    left: 10px;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: #000;
  }
  
  .tooltipAction.right .tooltipArrow {
    top: 50%;
    left: auto;
    margin-left: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: #000;
  }
  
  .tooltipAction.top .tooltipArrow {
    top: auto;
    bottom: -5px;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000;
  }
  
  .tooltipAction.left .tooltipArrow {
    top: 50%;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: #000;
    right: -5px;
    left: auto;
  }
  
  .tooltipAction.bottom .tooltipArrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000;
  }
  
  .tooltipAction .tooltipArrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-right-color: transparent;
    border-style: solid;
  }
  
  .tooltipAction .tooltipLabel {
    padding: 3px 8px;
    color: #fff;
    text-align: left;
    background-color: #595858;
    border-radius: 4px;
    font-size: 16px;
  }
  
  @media screen and (min-width: 470px) and (max-width: 900px) {
    .Tooltip_Groups .tooltipAction {
      width: 150px;
      left: -58px;
    }
  }
  
  @media screen and (min-width: 280px) and (max-width: 900px) {
    .Tooltip_Group_Banner .tooltipAction {
      width: 240px;
      left: -98px;
    }
    .Tooltip_Groups .tooltipAction {
      width: 240px ;
      left: 30px ;
      top: 0px;
    }
    .Tooltip_Groups {
      width: 100%;
      display: block;
      padding-left: 0;
    }
    .ToolTip__icon {
      margin-left: 0px;
    }
  }
  