.day_use_availability_section_wrapper {
    background: var(--ion-color-secondary);;
    /* height: 250px; */
    width: 100%;
    margin: auto auto 20px auto;
    padding: 20px;
    gap: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 600px) {
    .day_use_availability_section_wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;
      flex-direction: row;
    }
}

.day_use_avail_row_wrapper {
    display: flex;
    padding: 0 0 10px 0;
    width: 100%;
    align-items: center;
    gap: 16px;
}

.day_use_avail_section_icon {
    height: 22px;
    width: 22px;
}

.fade {
    opacity: 0.4;
    pointer-events: none;
}

.row_width {
    width: 100%;
}

@media screen and (max-width: 600px) {
    .day_use_availability_section_wrapper {
        flex-direction: column !important;
    }

    .row_width {
        width: 100%;
    }
}