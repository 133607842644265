.bl-full-height {
  height: 100%;
}

.bl-full-width {
  width: 100%;
}

.bl-flex {
  display: flex;
}

.bl-flex-column {
  display: flex;
  flex-direction: column;
}

/* margin */
/* 4 sides */
.bl-margin-xxs {
  margin: var(--bl-margin-xxs);
}
.bl-margin-xs {
  margin: var(--bl-margin-xs);
}
.bl-margin-s {
  margin: var(--bl-margin-s);
}
.bl-margin-m {
  margin: var(--bl-margin-m);
}
.bl-margin-l {
  margin: var(--bl-margin-l);
}
.bl-margin-xl {
  margin: var(--bl-margin-xl);
}
/* 4 sides */

/* top */
.bl-margin-t-xxs {
  margin-top: var(--bl-margin-xxs);
}
.bl-margin-t-xs {
  margin-top: var(--bl-margin-xs);
}
.bl-margin-t-s {
  margin-top: var(--bl-margin-s) !important;
}
.bl-margin-t-m {
  margin-top: var(--bl-margin-m);
}
.bl-margin-t-l {
  margin-top: var(--bl-margin-l);
}
.bl-margin-t-xl {
  margin-top: var(--bl-margin-xl);
}
/* top */

/* bottom */
.bl-margin-b-xxs {
  margin-bottom: var(--bl-margin-xxs);
}
.bl-margin-b-xs {
  margin-bottom: var(--bl-margin-xs);
}
.bl-margin-b-s {
  margin-bottom: var(--bl-margin-s);
}
.bl-margin-b-m {
  margin-bottom: var(--bl-margin-m);
}
.bl-margin-b-l {
  margin-bottom: var(--bl-margin-l);
}
.bl-margin-b-xl {
  margin-bottom: var(--bl-margin-xl);
}
/* bottom */

/* left */
.bl-margin-l-xxs {
  margin-left: var(--bl-margin-xxs);
}
.bl-margin-l-xs {
  margin-left: var(--bl-margin-xs);
}
.bl-margin-l-s {
  margin-left: var(--bl-margin-s);
}
.bl-margin-l-m {
  margin-left: var(--bl-margin-m);
}
.bl-margin-l-l {
  margin-left: var(--bl-margin-l);
}
.bl-margin-l-xl {
  margin-left: var(--bl-margin-xl);
}
/* left */

/* right */
.bl-margin-r-xxs {
  margin-right: var(--bl-margin-xxs);
}
.bl-margin-r-xs {
  margin-right: var(--bl-margin-xs);
}
.bl-margin-r-s {
  margin-right: var(--bl-margin-s);
}
.bl-margin-r-m {
  margin-right: var(--bl-margin-m);
}
.bl-margin-r-l {
  margin-right: var(--bl-margin-l);
}
.bl-margin-r-xl {
  margin-right: var(--bl-margin-xl);
}
/* right */
/* margin */

/* padding */
/* 4 sides */
.bl-padding-xxs {
  padding: var(--bl-padding-xxs);
}
.bl-padding-xs {
  padding: var(--bl-padding-xs);
}
.bl-padding-s {
  padding: var(--bl-padding-s);
}
.bl-padding-m {
  padding: var(--bl-padding-m);
}
.bl-padding-l {
  padding: var(--bl-padding-l);
}
.bl-padding-xl {
  padding: var(--bl-padding-xl);
}
/* 4 sides */

/* top */
.bl-padding-t-xxs {
  padding-top: var(--bl-padding-xxs);
}
.bl-padding-t-xs {
  padding-top: var(--bl-padding-xs);
}
.bl-padding-t-s {
  padding-top: var(--bl-padding-s);
}
.bl-padding-t-m {
  padding-top: var(--bl-padding-m);
}
.bl-padding-t-l {
  padding-top: var(--bl-padding-l);
}
.bl-padding-t-xl {
  padding-top: var(--bl-padding-xl);
}
/* top */

/* bottom */

.bl-padding-b-xxs {
  padding-bottom: var(--bl-padding-xxs);
}
.bl-padding-b-xs {
  padding-bottom: var(--bl-padding-xs);
}
.bl-padding-b-s {
  padding-bottom: var(--bl-padding-s);
}
.bl-padding-b-m {
  padding-bottom: var(--bl-padding-m);
}
.bl-padding-b-l {
  padding-bottom: var(--bl-padding-l);
}
.bl-padding-b-xl {
  padding-bottom: var(--bl-padding-xl);
}
/* bottom */

/* left */
.bl-padding-l-xxs {
  padding-left: var(--bl-padding-xxs);
}
.bl-padding-l-xs {
  padding-left: var(--bl-padding-xs);
}
.bl-padding-l-s {
  padding-left: var(--bl-padding-s);
}
.bl-padding-l-m {
  padding-left: var(--bl-padding-m);
}
.bl-padding-l-l {
  padding-left: var(--bl-padding-l);
}
.bl-padding-l-xl {
  padding-left: var(--bl-padding-xl);
}
/* left */

/* right */
.bl-padding-r-xxs {
  padding-right: var(--bl-padding-xxs);
}
.bl-padding-r-xs {
  padding-right: var(--bl-padding-xs);
}
.bl-padding-r-s {
  padding-right: var(--bl-padding-s);
}
.bl-padding-r-m {
  padding-right: var(--bl-padding-m);
}
.bl-padding-r-l {
  padding-right: var(--bl-padding-l);
}
.bl-padding-r-xl {
  padding-right: var(--bl-padding-xl);
}
/* right */
/* padding */
:root {
  /* Variables */

  /* margin */
  --bl-margin-xxxs: 2px;
  --bl-margin-xxs: 4px;
  --bl-margin-xs: 8px;
  --bl-margin-s: 16px;
  --bl-margin-m: 25px;
  --bl-margin-l: 32px;
  --bl-margin-xl: 40px;
  /* margins */

  /* padding */
  --bl-padding-xxxs: 2px;
  --bl-padding-xxs: 4px;
  --bl-padding-xs: 8px;
  --bl-padding-s: 16px;
  --bl-padding-m: 25px;
  --bl-padding-l: 32px;
  --bl-padding-xl: 40px;
  /* paddings */
}
