.mainFooterWrapper {
  width: 100%;
  height: 55px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: var(--ion-color-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 110;
  /* position: absolute; */
  /* bottom: 0; */
}
.policiyLinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.policiyLinks, .policiesLink {
  color: var(--ion-color-secondary);
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}
.policiesLink {
  text-decoration: underline;
}
.textInforamtion {
  padding-top: 5px;
  width: 90%;
  text-align: center;
  color: var(--ion-color-secondary);
}
.textInforamtion > * {
  font-size: 10px !important;
}
@media screen and (min-width: 280px) and (max-width: 750px) {
  .mainFooterWrapper {
    height: auto;
  }
}