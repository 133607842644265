.LoginForm {
    width: 100%;
    margin-top: 85px;
    padding-left: 20px;
  }
  
  .LoginForm__Submit {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .LoginForm__link {
    padding-top: 20px;
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    color: var(--ion-color-primary);
  }
  
  .LoginForm__link>img {
    margin-right: 15px;
  }
  
  .LoginForm__link * {
    color: var(--ion-color-primary);
  }
  
  .LoginForm__link>a {
    text-decoration: underline;
    margin-left: 5px;
  }
  
  .LoginForm__logo {
    width: 30px;
    height: 30px;
  }
  
  .LoginForm__AlertImg {
    width: 100%;
    height: 250px;
  }
  
  .LoginForm>*+* {
    margin-top: 30px;
  }
  
  .LoginForm>div:nth-child(3) {
    margin-top: 20px;
  }
  
  .LoginForm>div:nth-child(3) label {
    padding-bottom: 0;
  }
  
  .map {
    width: 100%;
    height: 280px;
    /* border: 2px solid #9aa199; */
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-bottom: 0.5rem;
    margin-top: 25px;
  }
  
  @media screen and (min-height: 700px) and (max-height: 865px) {
    .LoginForm {
      margin-top: 60px;
    }
  }
  
  @media screen and (min-height: 228px) and (max-height: 1300px) {
    .LoginForm {
      margin-top: 20px;
      padding-right: 0px;
      padding-left: 0px;
    }
  
    .LoginForm__link {
      white-space: nowrap;
    }
  }
  
  @media screen and (min-width: 228px) and (max-width: 450px) {
    .LoginForm__link {
      font-size: 1.1rem;
    }
  }
  
  @media screen and (min-width: 228px) and (max-width: 345px) {
    .LoginForm__link {
      font-size: 1rem;
    }
  }
  
  @media screen and (min-width: 228px) and (max-width: 307px) {
    .LoginForm__link {
      font-size: 1rem;
      white-space: nowrap;
    }
  }