
/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
	--ion-font-family: "Arial";
	--ion-text-color: #0F3448;
	--ion-color-primary: #0F3448;
	--ion-color-primary-rgb: 15,52,72;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #0d2e3f;
	--ion-color-primary-tint: #27485a;

	--ion-color-secondary: #F9ECDE;
	--ion-color-secondary-rgb: 249,236,222;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #EDE0D3;
	--ion-color-secondary-tint: #faeee1;

	--ion-color-tertiary: #3592B9;
	--ion-color-tertiary-rgb: 53,146,185;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0,0,0;
	--ion-color-tertiary-shade: #2f80a3;
	--ion-color-tertiary-tint: #499dc0;

	--ion-color-success: #729B5A ;
	--ion-color-success-rgb: 114,155,90;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255,255,255;
	--ion-color-success-shade: #64884f;
	--ion-color-success-tint: #80a56b;

	--ion-color-warning: #E8D862;
	--ion-color-warning-rgb: 232,216,98;
	--ion-color-warning-contrast: #333333;
	--ion-color-warning-contrast-rgb: 51, 51, 51;
	--ion-color-warning-shade: #ccbe56;
	--ion-color-warning-tint: #eadc72;

	--ion-color-danger: #662734;
	--ion-color-danger-rgb: 102,39,52;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #5a222e;
	--ion-color-danger-tint: #753d48;

	--ion-color-white: #ffffff;
	--ion-color-white-rgb: 255,255,255;
	--ion-color-white-contrast: #000000;
	--ion-color-white-contrast-rgb: 0,0,0;
	--ion-color-white-shade: #e0e0e0;
	--ion-color-white-tint: #ffffff;

	--ion-color-light: #F2F2F4;
	--ion-color-light-rgb: 242,242,244;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d5d5d7;
	--ion-color-light-tint: #f3f3f5;
	--ion-color-informational: #D5ECEF;

	--ion-color-medium: #707070;
	--ion-color-medium-rgb: 112,112,112;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255,255,255;
	--ion-color-medium-shade: #636363;
	--ion-color-medium-tint: #7e7e7e;

  --ion-color-dark: #121212;
	--ion-color-dark-rgb: 18,18,18;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255,255,255;
	--ion-color-dark-shade: #101010;
	--ion-color-dark-tint: #2a2a2a;
}
