.warnerStayButton {
  min-height: 64px;
  cursor: pointer;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 18px;
  outline: none;
  text-transform: uppercase;
  font-weight: 500;
}

.primary {
  color: var(--ion-color-secondary);
  border: 2px solid var(--ion-color-primary);
  background-color: var(--ion-color-primary);
}

.primary-inverse {
  color: var(--ion-color-primary);
  border: 2px solid var(--ion-color-primary);
  background-color: var(--ion-color-secondary);
}

.secondary {
  color: var(--ion-color-secondary);
  border: 2px solid var(--ion-color-tertiary);
  background-color: var(--ion-color-tertiary);
}

.secondary-inverse {
  color: var(--ion-color-tertiary);
  border: 3px solid var(--ion-color-tertiary);
  background-color: var(--ion-color-secondary);
}

@media screen and (min-width: 1024px) {
  .primary:hover span {
    border-bottom: 2px solid var(--ion-color-secondary);
  }

  .primary-inverse:hover span {
    border-bottom: 2px solid var(--ion-color-primary);
  }

  .secondary:hover span {
    border-bottom: 2px solid var(--ion-color-secondary);
  }

  .secondary-inverse:hover span {
    border-bottom: 2px solid var(--ion-color-secondary);
  }
}

