.day_use_params_sel_section_wrapper {
    background: var(--ion-color-secondary);;
    height: 250px;
    left: 0;
    right: 0;
    padding: 16px;
}

.day_use_params_sel_section_icon {
    height: 24px;
    width: 24px;
}

.day_use_params_sel_section_guests_date_wrapper {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
}

.day_use_params_sel_section_field_wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.day_use_params_sel_section_guests_field_wrapper {
    width: 175px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
}

.day_use_params_sel_section_guests_input_field {
    height: 34px;
    width: 100px;
    text-align: center;
    font-size: 18px;
    display: inline-block;
    vertical-align: middle;
    color: var(--ion-color-primary);
    cursor: default;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
