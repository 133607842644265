.day_use_register_view_wrapper {
    background: var(--ion-color-secondary);;
    width: 100%;
    left: 0;
    right: 0;
    padding: 20px;
}

.day_use_reg_view_checkbox_wrapper {
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
}

.day_use_reg_view_error_wrapper {
    background: var(--ion-color-danger);
    color: var(--ion-color-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.day_use_reg_view_btns_wrapper {
    display: flex;
    width: 100%;
    left: 0;
    right: 0;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
