.BlurryWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .BlurryWrapper {
      position: relative;
      z-index: 5;
    }
    .BlurryWrapper__helper {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      filter: blur(25px);
      border-radius: 10px;
      z-index: -1;
    }
    .BlurryWrapper__helper_login {
      filter: blur(8px);
    }
    .BlurryWrapper__helper_maintenance {
      filter: blur(25px);
    }
  }
  