.day_use_wrapper {
  flex: 1 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  background: var(--ion-color-primary);
  width: 100%;
  height: 85px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  position: unset;
  z-index: 10000;
}

.content {
  display: flex;
  width: 95%;
  margin: 30px auto;
  gap: 16px;
}

.mobile {
  position: relative;
  display: flex;
}

.day_use_avail_section_icon {
  width: 30px;
  height: 30px;
}
