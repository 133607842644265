.day_use_header_section_logo_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 20px;
    z-index: 22;
}

.day_use_header_section_header_wrapper {
    width: 100%;
    height: 140px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    mask-image: linear-gradient(0deg, #F2F2F400 0%, #F2F2F4DD 72%, #F2F2F4 100%);
    -webkit-mask-image: linear-gradient(0deg, #F2F2F400 0%, #F2F2F4DD 72%, #F2F2F4 100%);
}

.day_use_header_section_hotel_sel_wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
}

.day_use_header_section_icon {
    width: 25px;
    height: 2.5rem;
    margin-left: 15px;
    cursor: pointer;
}

.day_use_header_section_hotel_dropdown_wrapper {
    display: flex;
    width: calc(100% - 40px);
    justify-content: center;
    align-items: center;
}

.WelcomeAnticipationImage {
    height: 115px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin: 2px;
  }