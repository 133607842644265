.day_use_basket_details_btns_wrapper {
    display: flex;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto auto 20px auto;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.day_use_empty_basket_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}