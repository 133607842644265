.bl-icon-xxsm {
    width: 16px;
    height: 16px;
    vertical-align: text-top;
}

.bl-icon-xsm {
    width: 20px;
    height: 20px;
    vertical-align: bottom;
}

.bl-icon-sm {
    width: 24px;
    height: 24px;
    vertical-align: bottom;
}

.bl-icon-xmd {
    width: 28px;
    height: 28px;
    vertical-align: bottom;
}

.bl-icon-md {
    width: 32px;
    height: 32px;
    vertical-align: bottom;
}

.bl-icon-smd {
    width: 39px;
    height: 39px;
    vertical-align: bottom;
}

.bl-icon-lg {
    width: 55px;
    height: 55px;
    vertical-align: bottom;
}

.bl-icon-blue {
    width: 24px;
    height: 24px;
    fill: var(--ion-color-secondary);
}

.bl-disable {
    pointer-events: none;
}

.bl-disable-fade {
    opacity: 0.4;
    pointer-events: none;
}

.bl-error-border {
    border: 1px solid var(--ion-color-danger);
}

.bl-box-height-default {
  height: 50px !important;
}

.bl-box-height-sm {
  height: 48px !important;
  min-height: 48px !important; 
}

.bl-box-height-md {
  height: 56px !important;
}

.bl-bottom-position {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.bl-strong {
    font-weight: bold;
}

.ellipsis-3rd-row {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.ellipsis-4rd-row {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.ellipsis-6rd-row {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.BLItem_isUnavailable {
    pointer-events: none;
    cursor: default;
    opacity: 0.38;
}

.BLItem_unavailable_banner {
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ion-color-medium);
  background-color: rgba(0,0,0,.38);
  color: var(--ion-color-white);
  z-index: 2;
}

.bl-default-pointer::part(native) {
    cursor: default !important;
}
