.popUpWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.grayOutScreen {
  background-color: #ccc;
  opacity: 0.5;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}
