.day_use_footer_wrapper {
    width: 100%;
    background-color: var(--ion-color-primary);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px 0;
    bottom: 0;
    position: absolute;
    height: 75px;
    border-bottom: 1px solid var(--ion-color-primary-tint);
}
