.day_use_booking_view_wrapper {
    background: var(--ion-color-secondary);;
    width: 100%;
    left: 0;
    right: 0;
    padding: 20px;
    color: var(--ion-color-primary);
}

.day_use_icon {
    height: 30px;
    width: 30px;
}