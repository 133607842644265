.InformationalBoxHeavy {
    width: 100%;
    padding: 25px;
    background-color: var(--ion-color-primary);
    text-align: center;
    color: var(--ion-color-secondary);
}

.InformationalBoxHeavy_title {
    margin-bottom: 15px;
    font-weight: bold;
}